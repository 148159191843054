requirejs.config({
    baseUrl: '/Assets/js/modules',
    waitSeconds: 0,
    urlArgs: 'v=0.0.8',//改动不合并到入口文件的js文件时才需要修改
    enforceDefine: false,//如果设置为true，当一个脚本不是通过define()定义且不具备可供检查的shim导出字串值时，就会抛出错误.
    paths: {
        //基础组件部分
        'jquery': '../libs/jquery-1.10.2.min',
        'underscore': '../libs/underscore-1.7.0.min',
        'class': '../libs/class.min',
        'common': '../global/common',
        'util': '../global/util',

        //require相关插件
        'text': '../libs/require/require-text.min',
        'css': '../libs/require/require-css.min',
        'css-builder': '../libs/require/css-builder',
        'normalize': '../libs/require/normalize',

         //上传图片
        'upload': '../plugins/upload/app',
        'webuploader': '../plugins/upload/webuploader.min',

        //福文本编辑框
        'ueditor.all.min': '../plugins/ueditor/ueditor.all.min',
        'ZeroClipboard.min': '../plugins/ueditor/third-party/zeroclipboard/ZeroClipboard.min',
        'shCore': '../plugins/ueditor/third-party/SyntaxHighlighter/shCore',
        'shBrushJScript': '../plugins/ueditor/third-party/SyntaxHighlighter/shBrushJScript',
        'zh-cn': '../plugins/ueditor/lang/zh-cn/zh-cn',

        //数据校验
        'validate': '../libs/jquery.validate',

        //分页插件
        'jqPaginator': '../plugins/jqPaginator.min',

        //新日期插件
        'calender': '../plugins/calender/js/calender',

        //js滑动插件
        'swiper': '../plugins/swiper/idangerous.swiper2.7.6',

        //弹框插件
        'dialog': '../plugins/dialog/dialog',

        //图片延迟加载
        'jquery.lazyload': '../plugins/jquery.lazyload.min',

        //特效背景
        'jquery.particleground': '../libs/jquery.particleground.min',

        'oCanvas': '../plugins/oCanvas',
        'jquery.form': '../libs/jquery.form',
        //看图插件viewer
        'viewer': '../plugins/viewer/viewer-custom',
        //看图插件viewer
        'jquery.onepage-scroll': '../plugins/onepage-scroll/jquery.onepage-scroll',

    },
    shim: {
        'jquery': { exports: '$' },

        'upload': {
            deps: ['jquery', 'webuploader']
        },
        'webuploader': {
            deps: ['jquery', 'css!../plugins/upload/webuploader.css', 'css!../plugins/upload/style.css']
        },

        'ueditor.all.min': {
            deps: ['../plugins/ueditor/ueditor.config']
        },
        'zh-cn': {
            deps: ['ueditor.all.min']
        },

        'validate': {
            deps: ['jquery']
        },

        'jqPaginator': {
            deps: ['jquery']
        },

        'calender': {
            deps: ['css!../plugins/calender/css/calender.css']
        },

        'swiper': {
            deps: ['jquery', 'css!../plugins/swiper/swiper.min.css']
        },

        'dialog': {
            deps: ['css!../plugins/dialog/dialog.css']
        },

        'jquery.lazyload': {
            deps: ['jquery']
        },

        'jquery.particleground': {
            deps: ['jquery']
        },
        'jquery.form': {
            deps: ['jquery']
        },
        'viewer': {
            deps: ['jquery', 'css!../plugins/viewer/viewer-custom.css']
        },
        'jquery.onepage-scroll': {
            deps: ['jquery', 'css!../plugins/onepage-scroll/onepage-scroll.css']
        }
    }
});
define("../config", function(){});

requirejs(['../config'], function() {
    requirejs(['jquery', 'main/viewModel/index'], function($, ViewModel) {
        $(function() {
            new ViewModel();
        });
    });
});

define("../main/index", function(){});

define('class',[],function(){Object.keys||(Object.keys=function(e){var f=[],d;for(d in e)Object.prototype.hasOwnProperty.call(e,d)&&f.push(d);return f});var k=function(){function e(a){if(!a)return[];if("toArray"in Object(a))return a.toArray();for(var b=a.length||0,c=Array(b);b--;)c[b]=a[b];return c}function f(a,b){for(var c in b)b.hasOwnProperty(c)&&(a[c]=b[c]);return a}function d(){}var l=function(){for(var a in{toString:1})if("toString"===a)return!1;return!0}();return{create:function(){function a(){this.initialize.apply(this,
arguments)}var b=null,c=e(arguments);"[object Function]"===Object.prototype.toString.call(c[0])&&(b=c.shift());f(a,k.Methods);a.superclass=b;a.subclasses=[];b&&(d.prototype=b.prototype,a.prototype=new d,b.subclasses.push(a));for(var b=0,m=c.length;b<m;b++)a.addMethods(c[b]);a.prototype.initialize||(a.prototype.initialize=Prototype.emptyFunction);return a.prototype.constructor=a},extend:f,Methods:{addMethods:function(a){var b=this.superclass&&this.superclass.prototype,c=Object.keys(a);l&&(a.toString!=
Object.prototype.toString&&c.push("toString"),a.valueOf!=Object.prototype.valueOf&&c.push("valueOf"));for(var d=0,f=c.length;d<f;d++){var e=c[d],g=a[e];if(b&&Object.isFunction(g)&&"$super"==g.argumentNames()[0]){var h=g,g=function(a){return function(){return b[a].apply(this,arguments)}}(e).wrap(h);g.valueOf=function(a){return function(){return a.valueOf.call(a)}}(h);g.toString=function(a){return function(){return a.toString.call(a)}}(h)}this.prototype[e]=g}return this}}}}();return k});
(function (factory) {
    if (typeof define === 'function' && define.amd) {
        define('common',['jquery'], factory);
    } else {
        factory(jQuery);
    }
})(function ($) {
    $('.navbar-toggle').bind('click', function () {
        $('.navbar-collapse').toggle();
        $('.navbar-collapse-mask').toggle();
    });
    $('.navbar-collapse-mask').bind('click', function () {
        $('.navbar-collapse').hide();
        $(this).hide();
    });
});
define('main/viewModel/index',['jquery', 'class','common'], function ($, Class) {
    var Index = Class.create({
        initialize: function() {
            //var containers = document.querySelectorAll('.skynet-cases-container');
            //var tab = document.querySelectorAll('.skynet-cases-tab');
            //var tabsParent = document.getElementById('skynet-cases-tabs');
            //tabsParent.onclick = function(ev) {
            //    ev = ev || window.event;
            //    var target = ev.target;
            //    if (target.nodeName.toLowerCase() === 'span') {
            //        target = ev.target.parentElement;
            //    }
            //    for (var i = 0, len = tab.length; i < len; i++) {
            //        tab[i].className = tab[i].className.replace(/active/, '');
            //    }
            //    for (var j = 0, lfn = containers.length; j < lfn; j++) {
            //        containers[j].style.display = 'none';
            //    }
            //    target.className += ' active';
            //    var _id = target.getAttribute('data-id');
            //    document.getElementById(_id).style.display = 'block';
            //};

            //$('.main').onepage_scroll({
            //    sectionContainer: "section",
            //    responsiveFallback: 600,
            //    //updateURL: true,
            //    loop: false,
            //    pagination: false,
            //});
            //$(".skynet-header-index li a").click(function () {
            //    var page_index = $(this).data("index");
            //    $('.main').moveTo(page_index);
            //});
        },
    });
    return Index;
});

